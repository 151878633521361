<template>
  <div class="fx-center full-height full-width">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>
    <div id="forgot-password" class="fx-center fx-wrap">
      
      <router-link :to="{ name: 'home'}" class="fx fx-a-i-cent">
        <div>
          <img
            class="c-account-box__headline"
            :src="baseUrl + 'img/logo.png'"
            :alt="app_name + ' logo'"
          />
        </div>
      </router-link>

      <div class="c-account-box__headline">Redefinição de Senha</div>
      <div class="c-account-box__content">
        <form class="c-form-account">
          <div class="js-valid-row">
            <div class>
              <label class="c-ui-input c-ui-input--account-login">
                <input
                  class="c-ui-input__field c-ui-input__field--right-placeholder"
                  type="text"
                  v-model="email"
                  placeholder="email"
                  tabindex="1"
                />
              </label>
            </div>
          </div>
          <div class>
            <div class>
              <button class="btn clickable" type="button" @click="doReset">Enviar E-mail</button>
            </div>
          </div>
          <span v-if="message && message.length > 0">{{ message }}</span>
        </form>
        <router-link :to="{ name: 'login'}">
          <i class="mg-2 fas fa-chevron-left"></i>
          Voltar
        </router-link>
      </div>
    </div>
  </div>
</template>
<style scoped>
#forgot-password {
  flex-direction: column;
  width: 100%;
}
</style>

<script>
import axios from "axios";
import util from "@/services/util";
import { debounce } from "debounce";
import HeaderBuilder from "@/services/header-builder.js";
import { apiBaseUrl } from "@/services/api.js";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet

export default {
  name: "ForgotPassword",
  components: {
    Loading
  },
  data: function() {
    return {
      isLoading: false,
      message: null,
      email: "",
      app_name: process.env.VUE_APP_NAME,
      tenant_id: process.env.VUE_APP_TENANT_ID,
      baseUrl: process.env.BASE_URL,
    };
  },
  mounted() {
    this.$root.$emit("showOnlyRouter");
  },
  beforeDestroy() {
    this.$root.$emit("showWholeApp");
  },
  methods: {
    doReset: debounce(
      function() {
        this.isLoading = true;
        axios
          .post(
            `${apiBaseUrl}/api/password/forgot/`,
            { email: this.email },
            { headers: HeaderBuilder.build() }
          )
          .then(
            response => {
              this.message =
                "Cheque seu e-mail e clique no botão para redefinir sua senha. Não se esqueça de verificar a caixa de spam!";
            },
            errorResponse => {
              this.$notify({
                duration : 24000,
                type: "error",
                title: "Erro",
                text: util.stringifyAxiosError(errorResponse)
              });
              this.message = "Erro ao enviar e-mail de redefinição de senha!";
            }
          )
          .finally(() => {
            this.isLoading = false;
          });
      },
      1000,
      true
    )
  }
};
</script>
